// import React from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Main from './layouts/Main';
// import NotFound from "./Pages/NotFound";

// import publicRoutes from "./routes/PublicRoutes";
// import protectedRoutes from "./routes/ProtectedRoutes";

// // import css
// import "./assets/css/remixicon.css";

// // import scss
// import "./scss/style.scss";


// // set skin on load
// window.addEventListener("load", function () {
//   let skinMode = localStorage.getItem("skin-mode");
//   let HTMLTag = document.querySelector("html");

//   if (skinMode) {
//     HTMLTag.setAttribute("data-skin", skinMode);
//   }
// });

// export default function App() {
//   return (
//     <React.Fragment>
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Main />}>
//             {protectedRoutes.map((route, index) => {
//               return (
//                 <Route
//                   path={route.path}
//                   element={route.element}
//                   key={index}
//                 />
//               )
//             })}
//           </Route>
//           {publicRoutes.map((route, index) => {
//             return (
//               <Route
//                 path={route.path}
//                 element={route.element}
//                 key={index}
//               />
//             )
//           })}
//           <Route path="*" element={<NotFound />} />
//         </Routes>
//       </BrowserRouter>
//     </React.Fragment>
    
//   );
// }

import React,{useEffect} from "react";
import { BrowserRouter, Routes, Route,useNavigate } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./Pages/NotFound";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";

import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import { isAuthenticated } from "./utils/auth";
// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});
const RedirectRoute = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/dashboard/add-user");
    } else {
      navigate("/pages/signin");
    }
  }, [navigate]);
  return null;
};
export default function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<RedirectRoute />} />
          <Route path="/" element={<Main />}>
            <Route element={<PrivateRoute />}>
              {protectedRoutes.map((route, index) => (
                <Route
                  path={route.path}
                  element={<protectedRoutes>{route.element}</protectedRoutes>}
                  key={index}
                />
              ))}
            </Route>
          </Route>
          <Route element={<PublicRoute />}>
            {publicRoutes.map((route, index) => (
              <Route
                path={route.path}
                element={route.element}
                key={index}
              />
            ))}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}
