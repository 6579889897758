import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../layouts/Header';
import { createEntry } from '../utils/api';

const AddEntryForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    date: '',
    sex: '',
    type: '',
    address: '',
    disease: '',
    otherDisease: '',
    caseDetail: '',
    totalVisit: '',
    outStandingAmount:'',
    name: '',
    age: '',
    dressing: '',
    dressingCost: '',
    medicines: [{ name: '', price: '' }],
    phoneNumber:'',
    
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleMedicineChange = (index, event) => {
    const values = [...formData.medicines];
    values[index][event.target.name] = event.target.value;
    setFormData({
      ...formData,
      medicines: values,
    });
  };

  const handleAddMedicine = () => {
    setFormData({
      ...formData,
      medicines: [...formData.medicines, { name: '', price: '' }],
    });
  };

  const handleRemoveMedicine = (index) => {
    const values = [...formData.medicines];
    values.splice(index, 1);
    setFormData({
      ...formData,
      medicines: values,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createEntry(formData);
      alert('Form submitted successfully');
      navigate('/dashboard/user-table'); // Redirect to the desired page after submission
    } catch (err) {
      console.error(err);
      alert('Error submitting form');
    }
  };

  return (
    <>
      <Header />
      <div className="main main-app p-3">
        <h2>Add New Entry</h2>
        <Form className='row' onSubmit={handleSubmit}>
          <Form.Group className='col-5' controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className='col-5' controlId="formAge">
            <Form.Label>Age</Form.Label>
            <Form.Control type="number" name="age" value={formData.age} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className='col-5' controlId="formAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control type="text" name="address" value={formData.address} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className='col-5' controlId="formSex">
            <Form.Label>Sex</Form.Label>
            <Form.Control type="text" name="sex" value={formData.sex} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className='col-5' controlId="formPhoneNumber">
            <Form.Label>Phone Number </Form.Label>
            <Form.Control type="number" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className='col-5' controlId="formDate">
            <Form.Label>Date</Form.Label>
            <Form.Control type="date" name="date" value={formData.date} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className='col-5' controlId="formType">
            <Form.Label>x-ray/mri</Form.Label>
            <Form.Control type="text" name="type" value={formData.type} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className='col-5' controlId="formoutStandingAmount">
            <Form.Label>Balance </Form.Label>
            <Form.Control type="number" name="outStandingAmount" value={formData.outStandingAmount} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className='col-5' controlId="formDisease">
            <Form.Label>Disease</Form.Label>
            <Form.Control type="text" name="disease" value={formData.disease} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className='col-5' controlId="formOtherDisease">
            <Form.Label>Other Disease</Form.Label>
            <Form.Control type="text" name="otherDisease" value={formData.otherDisease} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className='col-5' controlId="formCaseDetail">
            <Form.Label>Case Detail</Form.Label>
            <Form.Control type="text" name="caseDetail" value={formData.caseDetail} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className='col-5' controlId="formTotalVisit">
            <Form.Label>How many visits almost</Form.Label>
            <Form.Control type="text" name="totalVisit" value={formData.totalVisit} onChange={handleChange} required />
          </Form.Group>

          <hr className='m-2 col-lg-10 pe-3'/>
          <Form.Group className='col-7' controlId="formDressing">
            <Form.Label>Treatment</Form.Label>
            <Form.Control type="text" name="dressing" value={formData.dressing} onChange={handleChange} required />
          </Form.Group>
          <Form.Group className='col-3' controlId="formdressingCost">
            <Form.Label> Cost</Form.Label>
            <Form.Control type="number" name="dressingCost" value={formData.dressingCost} onChange={handleChange} required />
          </Form.Group>
        
          

          {formData.medicines.map((medicine, index) => (
            <div key={index} className='row'>
              <Form.Group className='col-6' controlId={`formMedicineName${index}`}>
                <Form.Label>Medicine</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={medicine.name}
                  onChange={(event) => handleMedicineChange(index, event)}
                  required
                />
              </Form.Group>
              <Form.Group className='col-4' controlId={`formMedicinePrice${index}`}>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  value={medicine.price}
                  onChange={(event) => handleMedicineChange(index, event)}
                  required
                />
              </Form.Group>
              <Button
                size='sm'
                className='col-2 mt-4'
                variant="danger"
                onClick={() => handleRemoveMedicine(index)}
              >
                Remove
              </Button>
            </div>
          ))}
          <div className='d-flex flex-column'>
          <Button size='sm' className='col-2 mx-2 mt-4' variant="secondary" onClick={handleAddMedicine}>
            Add Medicine
          </Button>
          <Button size='sm' className='col-5 mx-3 mt-4' variant="primary" type="submit">
            Submit
          </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddEntryForm;



// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Form, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Header from '../layouts/Header';
// import { createEntry } from '../utils/api';

// const AddEntryForm = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     date: '',
//     sex: '',
//     type: '',
//     address: '',
//     disease: '',
//     otherDisease: '',
//     caseDetail: '',
//     totalVisit: '',
//     name: '',
//     age: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
   
//   };

//   return (
//     <>
//       <Header />
//       <div className="main main-app p-3">
//         <h2>Add New Entry</h2>
//         <Form className='row' onSubmit={handleSubmit}>
//           <Form.Group className='col-5' controlId="formName">
//             <Form.Label>Name</Form.Label>
//             <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formAge">
//             <Form.Label>Age</Form.Label>
//             <Form.Control type="number" name="age" value={formData.age} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formDate">
//             <Form.Label>Date</Form.Label>
//             <Form.Control type="date" name="date" value={formData.date} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formSex">
//             <Form.Label>Sex</Form.Label>
//             <Form.Control type="text" name="sex" value={formData.sex} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formType">
//             <Form.Label>Type</Form.Label>
//             <Form.Control type="text" name="type" value={formData.type} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formAddress">
//             <Form.Label>Address</Form.Label>
//             <Form.Control type="text" name="address" value={formData.address} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formDisease">
//             <Form.Label>Disease</Form.Label>
//             <Form.Control type="text" name="disease" value={formData.disease} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formOtherDisease">
//             <Form.Label>Other Disease</Form.Label>
//             <Form.Control type="text" name="otherDisease" value={formData.otherDisease} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formCaseDetail">
//             <Form.Label>Case Detail</Form.Label>
//             <Form.Control type="text" name="caseDetail" value={formData.caseDetail} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formTotalVisit">
//             <Form.Label>Total Visit</Form.Label>
//             <Form.Control type="number" name="totalVisit" value={formData.totalVisit} onChange={handleChange} required />
//           </Form.Group>
//           <Button size='sm' className='col-5 mx-3 mt-4' variant="primary" type="submit">Submit</Button>
//         </Form>
//       </div>
//     </>
//   );
// };

// export default AddEntryForm;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Form, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Header from '../layouts/Header';
// import { createEntry } from '../utils/api';

// const AddEntryForm = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     date: '',
//     sex: '',
//     type: '',
//     address: '',
//     disease: '',
//     otherDisease: '',
//     caseDetail: '',
//     totalVisit: '',
//     name: '',
//     age: '',
//     dressing:'',
//     dressingCost:''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await createEntry(formData);
//       alert('Form submitted successfully');
//       navigate('/dashboard/user-table'); // Redirect to the desired page after submission
//     } catch (err) {
//       console.error(err);
//       alert('Error submitting form');
//     }
//   };

//   return (
//     <>
//       <Header />
//       <div className="main main-app p-3">
//         <h2>Add New Entry</h2>
//         <Form className='row' onSubmit={handleSubmit}>
//           <Form.Group className='col-5' controlId="formName">
//             <Form.Label>Name</Form.Label>
//             <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formAge">
//             <Form.Label>Age</Form.Label>
//             <Form.Control type="number" name="age" value={formData.age} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formDate">
//             <Form.Label>Date</Form.Label>
//             <Form.Control type="date" name="date" value={formData.date} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formSex">
//             <Form.Label>Sex</Form.Label>
//             <Form.Control type="text" name="sex" value={formData.sex} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formType">
//             <Form.Label>x-ray/mri</Form.Label>
//             <Form.Control type="text" name="type" value={formData.type} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formAddress">
//             <Form.Label>Address</Form.Label>
//             <Form.Control type="text" name="address" value={formData.address} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formDisease">
//             <Form.Label>Disease</Form.Label>
//             <Form.Control type="text" name="disease" value={formData.disease} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formOtherDisease">
//             <Form.Label>Other Disease</Form.Label>
//             <Form.Control type="text" name="otherDisease" value={formData.otherDisease} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formCaseDetail">
//             <Form.Label>Case Detail</Form.Label>
//             <Form.Control type="text" name="caseDetail" value={formData.caseDetail} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-5' controlId="formTotalVisit">
//             <Form.Label>Total Visit</Form.Label>
//             <Form.Control type="number" name="totalVisit" value={formData.totalVisit} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-7' controlId="formDressing">
//             <Form.Label>Dressing</Form.Label>
//             <Form.Control type="text" name="dressing" value={formData.dressing} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-3' controlId="formdressingCost">
//             <Form.Label>Dressing Cost</Form.Label>
//             <Form.Control type="number" name="dressingCost" value={formData.dressingCost} onChange={handleChange} required />
//           </Form.Group>
//           {/*

// <h3 className=' m-auto mt-3'> Medicines </h3>
// <Form.Group className='col-3' controlId="formMedicineAlign1">
//             <Form.Label>Medicine </Form.Label>
//             <Form.Control type="test" name="dressingCost" value={formData.dressingCost} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-3' controlId="formMedicineCostAlign1">
//             <Form.Label>Medicine Cost</Form.Label>
//             <Form.Control type="number" name="dressingCost" value={formData.dressingCost} onChange={handleChange} required />
//           </Form.Group>

//           <Form.Group className='col-3' controlId="formMedicineAlign1">
//             <Form.Label>Medicine </Form.Label>
//             <Form.Control type="number" name="dressingCost" value={formData.dressingCost} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-3' controlId="formMedicineCostAlign1">
//             <Form.Label>Medicine Cost</Form.Label>
//             <Form.Control type="number" name="dressingCost" value={formData.dressingCost} onChange={handleChange} required />
//           </Form.Group>

//           <Form.Group className='col-3' controlId="formMedicineAlign1">
//             <Form.Label>Medicine </Form.Label>
//             <Form.Control type="number" name="dressingCost" value={formData.dressingCost} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-3' controlId="formMedicineCostAlign1">
//             <Form.Label>Medicine Cost</Form.Label>
//             <Form.Control type="number" name="dressingCost" value={formData.dressingCost} onChange={handleChange} required />
//           </Form.Group>

//           <Form.Group className='col-3' controlId="formMedicineAlign1">
//             <Form.Label>Medicine </Form.Label>
//             <Form.Control type="number" name="dressingCost" value={formData.dressingCost} onChange={handleChange} required />
//           </Form.Group>
//           <Form.Group className='col-3' controlId="formMedicineCostAlign1">
//             <Form.Label>Medicine Cost</Form.Label>
//             <Form.Control type="number" name="dressingCost" value={formData.dressingCost} onChange={handleChange} required />
//           </Form.Group> */}
//           <Button size='sm' className='col-5 mx-3 mt-4' variant="primary" type="submit">Submit</Button>
//         </Form>
//       </div>
//     </>
//   );
// };

// export default AddEntryForm;

