// // src/api.js
// import axios from 'axios';

// const API_URL = 'http://localhost:5000/api';

// export const login = async (username, password) => {
//   const response = await axios.post(`${API_URL}/auth/login`, { username, password });
//   return response.data;
// };

// export const createEntry = async (entry, token) => {
//   const response = await axios.post(`${API_URL}/entries`, entry, {
//     headers: { Authorization: `Bearer ${token}` }
//   });
//   return response.data;
// };

// export const getEntries = async (token) => {
//   const response = await axios.get(`${API_URL}/entries`, {
//     headers: { Authorization: `Bearer ${token}` }
//   });
//   return response.data;
// };

// export const updateEntry = async (id, entry, token) => {
//   const response = await axios.put(`${API_URL}/entries/${id}`, entry, {
//     headers: { Authorization: `Bearer ${token}` }
//   });
//   return response.data;
// };


// utils/api.js

import axios from 'axios';

export const createEntry = async (formData) => {
    const response = await axios.post('https://mohanbackend.onrender.com/submit-form', formData);
    return response.data;
};

export const loginUser = async (username, password) => {
  
  const response = await axios.post('https://mohanbackend.onrender.com/api/login', { username, password });
  return response.data; // Expecting response to contain a token
};
