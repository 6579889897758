import React,{useState} from "react";
import { Button, Card, Col, Form, Row,Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { loginUser } from '../utils/api';
export default function Signin() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const { token } = await loginUser(username, password);
      localStorage.setItem('token', token);
      navigate('/dashboard/add-user'); // Redirect to user table or any protected route
    } catch (err) {
      setError('Invalid credentials');
    }
  };
  return (
    <div className="page-sign">
       {error && <Alert variant="danger">{error}</Alert>}
      <Card className="card-sign">
        <Card.Header>
          <div className="header-logo mb-4">ShriMohanJi</div>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! </Card.Text>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Group controlId="formUsername">
              <Form.Label >User Name</Form.Label>
              <Form.Control  type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required />
              </Form.Group>
            </div>
            <div className="mb-4">
            <Form.Group controlId="formPassword">
              <Form.Label className="d-flex justify-content-between">Password </Form.Label>
              <Form.Control  type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required />
              </Form.Group>
            </div>
            <Button type="submit" variant="secondary" className="btn-sign ">Sign In</Button>

          

          
          </Form>
        </Card.Body>
        <Card.Footer>
         
        </Card.Footer>
      </Card>
    </div>
  )
}