export const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;
  
    const { exp } = JSON.parse(atob(token.split('.')[1]));
    if (exp * 1000 < Date.now()) {
      logout();
      return false;
    }
  
    return true;
  };
  
  export const logout = () => {
    localStorage.removeItem('token');
    window.location.href = '/pages/signin';
  };
  