import React from "react";

import AddUser from "../Pages/AddUser";
import UserTable from "../Pages/Usertable2";

const protectedRoutes = [
  { path: "dashboard/add-user", element: <AddUser /> },
  { path: "dashboard/user-table", element: <UserTable /> },
]

export default protectedRoutes;