// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Modal, Button, Form, Table } from 'react-bootstrap';
// import { FaEye, FaEdit } from 'react-icons/fa';
// import { MdDeleteForever } from 'react-icons/md';
// import { Grid, _ } from 'gridjs-react';
// import 'gridjs/dist/theme/mermaid.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Header from '../layouts/Header';
// import Footer from '../layouts/Footer';
// import hd1 from '../assets/img/hd2.png';

// const UserTable = () => {
//   const [data, setData] = useState([]);
//   const [show, setShow] = useState(false);
//   const [modalData, setModalData] = useState(null);
//   const [isEdit, setIsEdit] = useState(false);
//   const handleClose = () => setShow(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('https://mohanbackend.onrender.com/users');
//         setData(response.data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const toIST = (dateStr) => {
//     const date = new Date(dateStr);
//     return date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }).split(',')[0];
//   };

//   const handleShow = (row, isEdit) => {
//     setModalData({
//       ...row,
//       date: toIST(row.date),
//       medhub: Array.isArray(JSON.parse(row.medhub || '[]')) ? JSON.parse(row.medhub || '[]') : [],
//       medhubPrice: Array.isArray(JSON.parse(row.medhubPrice || '[]')) ? JSON.parse(row.medhubPrice || '[]') : [],
//       medicineHistory: row.medicineHistory ? JSON.parse(row.medicineHistory) : [],
//       dressingHistory: row.dressingHistory ? JSON.parse(row.dressingHistory) : [],
//       previousDates: row.previousDates ? JSON.parse(row.previousDates).map(toIST) : [],
//       medicines: row.medicines ? row.medicines : [], // Initialize medicines as an empty array if not present
//     });
//     setIsEdit(isEdit);
//     setShow(true);
//   };

//   const handleDelete = async (id) => {
//     try {
//       await axios.delete(`https://mohanbackend.onrender.com/users/delete/${id}`);
//       setData(data.filter((item) => item.id !== id));
//     } catch (error) {
//       console.error('Error deleting record:', error);
//     }
//   };

//   const handleSave = async () => {
//     try {
//       const updateData = {
//         date: modalData.date,
//         TotalVisit: modalData.TotalVisit,
//         medicines: modalData.medicines,
//         dressing: modalData.dressing,
//         dressingCost: modalData.dressingCost,
//       };

//       await axios.put(`https://mohanbackend.onrender.com/users/${modalData.id}`, updateData);

//       setData(
//         data.map((item) => {
//           if (item.id === modalData.id) {
//             return {
//               ...item,
//               ...updateData,
//               date: modalData.date,
//               medhub: modalData.medicines.map((med) => med.name),
//               medhubPrice: modalData.medicines.map((med) => med.price),
//             };
//           }
//           return item;
//         })
//       );

//       window.location.reload();
//       setShow(false);
//     } catch (error) {
//       console.error('Error updating data:', error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setModalData({
//       ...modalData,
//       [name]: value,
//     });
//   };

//   const handleMedicineChange = (index, event) => {
//     const values = [...modalData.medicines];
//     values[index][event.target.name] = event.target.value;
//     setModalData({
//       ...modalData,
//       medicines: values,
//     });
//   };

//   const handleAddMedicine = () => {
//     setModalData({
//       ...modalData,
//       medicines: [...modalData.medicines, { name: '', price: '' }],
//     });
//   };

//   const handleRemoveMedicine = (index) => {
//     const values = [...modalData.medicines];
//     values.splice(index, 1);
//     setModalData({
//       ...modalData,
//       medicines: values,
//     });
//   };


//   const combineHistory = () => {
//     const combined = [];

//     modalData.medicineHistory.forEach((medHist, index) => {
//       const dressHist = modalData.dressingHistory[index] || {};
//       combined.push({
//         date: medHist.date,
//         medicines: medHist.medhub.map((name, idx) => ({
//           name,
//           price: medHist.medhubPrice[idx],
//         })),
//         dressing: dressHist.dressing || '',
//         dressingCost: dressHist.dressingCost || '',
//       });
//     });

//     return combined;
//   };

//   return (
//     <>
//       <Header />
//       <div className="main main-app p-3 p-lg-4">
//         <p className="display-5 p-3">ShriMohanJi Bones Care Data</p>
//         <Grid
//           data={data.map((row) => [
//             row.customId,
//             row.date,
//             row.name,
//             row.Address,
//             _(
//               <div className="d-flex gap-2">
//                 <Button className="btn bg-danger-outline" onClick={() => handleShow(row, false)}>
//                   <FaEye />
//                 </Button>
//                 <Button className="btn bg-danger-outline" onClick={() => handleShow(row, true)}>
//                   <FaEdit />
//                 </Button>
//                 <Button className="btn bg-danger-outline" onClick={() => handleDelete(row.id)}>
//                   <MdDeleteForever />
//                 </Button>
//               </div>
//             ),
//           ])}
//           columns={['S.No', 'Date', 'Patient Name', 'Address', 'Action']}
//           search={true}
//           pagination={{
//             enabled: true,
//             limit: 70,
//           }}
//           className={{
//             table: 'table table-striped',
//           }}
//         />

//         <Modal size="lg" show={show} onHide={handleClose}>
//           <Modal.Header>
//             <Modal.Title>
//               {isEdit ? 'Edit Details' : (
//                 <div>
//                   <div className="d-flex">
//                     <img className="w-75 m-auto" src={hd1} alt="" />
//                   </div>
//                 </div>
//               )}
//             </Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             {modalData && (
//               <div>
//                 {isEdit ? (
//                   <Form>
//                     <Form.Group controlId="formName">
//                       <Form.Label>Name</Form.Label>
//                       <Form.Control type="text" name="name" value={modalData.name} onChange={handleChange} disabled />
//                     </Form.Group>
//                     <Form.Group controlId="formAge">
//                       <Form.Label>Age</Form.Label>
//                       <Form.Control type="number" name="age" value={modalData.age} onChange={handleChange} disabled />
//                     </Form.Group>
//                     <Form.Group controlId="formDate">
//                       <Form.Label>Update Date</Form.Label>
//                       <Form.Control type="date" name="date" value={modalData.date} onChange={handleChange} />
//                     </Form.Group>
//                     <Form.Group controlId="formTotalVisit">
//                       <Form.Label>Total Visit</Form.Label>
//                       <Form.Control type="number" name="TotalVisit" value={modalData.TotalVisit} onChange={handleChange} />
//                     </Form.Group>
//                     <Form.Group controlId="formDressing">
//                       <Form.Label>Dressing</Form.Label>
//                       <Form.Control type="text" name="dressing" value={modalData.dressing} onChange={handleChange} />
//                     </Form.Group>
//                     <Form.Group controlId="formDressingCost">
//                       <Form.Label>Dressing Cost</Form.Label>
//                       <Form.Control type="number" name="dressingCost" value={modalData.dressingCost} onChange={handleChange} />
//                     </Form.Group>
//                     <Form.Group controlId="formMedicines">
//                       <Form.Label>Medicines</Form.Label>
//                       {modalData.medicines.map((medicine, index) => (
//                         <div key={index} className="d-flex mb-2">
//                           <Form.Control
//                             type="text"
//                             placeholder="Medicine Name"
//                             name="name"
//                             value={medicine.name}
//                             onChange={(e) => handleMedicineChange(index, e)}
//                             className="mr-2"
//                           />
//                           <Form.Control
//                             type="number"
//                             placeholder="Price"
//                             name="price"
//                             value={medicine.price}
//                             onChange={(e) => handleMedicineChange(index, e)}
//                             className="mr-2"
//                           />
//                           <Button variant="danger" onClick={() => handleRemoveMedicine(index)}>
//                             Remove
//                           </Button>
//                         </div>
//                       ))}
//                       <Button variant="success" onClick={handleAddMedicine}>
//                         Add Medicine
//                       </Button>
//                     </Form.Group>
//                   </Form>
//                 ) : (
//                   <div>
//                     <h5>Patient Details</h5>
//                     <Table striped bordered hover>
//                       <tbody>
//                         <tr>
//                           <td><strong>Name:</strong></td>
//                           <td>{modalData.name}</td>
//                         </tr>
//                         <tr>
//                           <td><strong>Age:</strong></td>
//                           <td>{modalData.age}</td>
//                         </tr>
//                         <tr>
//                           <td><strong>Date:</strong></td>
//                           <td>{modalData.date}</td>
//                         </tr>
//                         <tr>
//                           <td><strong>Total Visits:</strong></td>
//                           <td>{modalData.TotalVisit}</td>
//                         </tr>
//                         <tr>
//                           <td><strong>Address:</strong></td>
//                           <td>{modalData.Address}</td>
//                         </tr>
//                         <tr>
//                           <td><strong>Disease:</strong></td>
//                           <td>{modalData.Disease}</td>
//                         </tr>
//                         <tr>
//                           <td><strong>Other Disease:</strong></td>
//                           <td>{modalData.otherDisease}</td>
//                         </tr>
//                         <tr>
//                           <td><strong>Case Detail:</strong></td>
//                           <td>{modalData.CaseDetail}</td>
//                         </tr>
//                         <tr>
//                           <td><strong>Treatment:</strong></td>
//                           <td>{modalData.dressing}</td>
//                         </tr>
//                         <tr>
//                           <td><strong>Dressing Cost:</strong></td>
//                           <td>{modalData.dressingCost}</td>
//                         </tr>
//                         <tr>
//                         <td><strong>Medicine Name:</strong></td>
//                         <td> {modalData.medhub.map((med, index) => (
//                                   <div key={index}>{med}</div>
//                                 ))}</td>  
//                         </tr>

//                         <tr>
//                         <td><strong>Medicine Price:</strong></td>
//                         <td> {modalData.medhubPrice.map((price, index) => (
//                                   <div key={index}>{price}</div>
//                                 ))}</td>  
//                         </tr>
//                       </tbody>
//                     </Table>
                  
//                     <h5>Details History</h5>
//                 <Table striped bordered hover>
//                   <thead>
//                     <tr>
//                       <th>Date</th>
//                       <th>Medicine Name</th>
//                       <th>Medicine Price</th>
//                       <th>Dressing</th>
//                       <th>Dressing Cost</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {combineHistory().map((history, index) => (
//                       <tr key={index}>
//                         <td>{history.date}</td>
//                         <td>
//                           <ul>
//                             {history.medicines.map((medicine, idx) => (
//                               <li key={idx}>{medicine.name}</li>
//                             ))}
//                           </ul>
//                         </td>
//                         <td>
//                           <ul>
//                             {history.medicines.map((medicine, idx) => (
//                               <li key={idx}>{medicine.price}</li>
//                             ))}
//                           </ul>
//                         </td>
//                         <td>{history.dressing}</td>
//                         <td>{history.dressingCost}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </Table>


                  
//                                     </div>
//                 )}
//               </div>
//             )}


            
//           </Modal.Body>
//           {isEdit && (
//             <Modal.Footer>
//               <Button variant="secondary" onClick={handleClose}>
//                 Close
//               </Button>
//               <Button variant="primary" onClick={handleSave}>
//                 Save Changes
//               </Button>
//             </Modal.Footer>
//           )}
//         </Modal>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default UserTable;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { FaEye, FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import { Grid, _ } from 'gridjs-react';
import 'gridjs/dist/theme/mermaid.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import hd1 from '../assets/img/hd2.png';

const UserTable = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://mohanbackend.onrender.com/users');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const toIST = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }).split(',')[0];
  };

  const handleShow = (row, isEdit) => {
    setModalData({
      ...row,
      date: toIST(row.date),
      medhub: Array.isArray(JSON.parse(row.medhub || '[]')) ? JSON.parse(row.medhub || '[]') : [],
      medhubPrice: Array.isArray(JSON.parse(row.medhubPrice || '[]')) ? JSON.parse(row.medhubPrice || '[]') : [],
      medicineHistory: row.medicineHistory ? JSON.parse(row.medicineHistory) : [],
      previousDates: row.previousDates ? JSON.parse(row.previousDates).map(toIST) : [],
      medicines: row.medicines ? row.medicines : [], // Initialize medicines as an empty array if not present
    });
    setIsEdit(isEdit);
    setShow(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://mohanbackend.onrender.com/users/delete/${id}`);
      setData(data.filter((item) => item.id !== id));
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const handleSave = async () => {
    try {
      const updateData = {
        name:modalData.name,
        date: modalData.date,
        TotalVisit: modalData.TotalVisit,
        outStandingAmount:modalData.outStandingAmount,
        medicines: modalData.medicines,
        dressing: modalData.dressing,
        dressingCost: modalData.dressingCost,
      };

      await axios.put(`https://mohanbackend.onrender.com/users/${modalData.id}`, updateData);

      setData(
        data.map((item) => {
          if (item.id === modalData.id) {
            return {
              ...item,
              ...updateData,
              name:modalData.name,
              date: modalData.date,
              TotalVisit:modalData.TotalVisit,
              medhub: modalData.medicines.map((med) => med.name),
              medhubPrice: modalData.medicines.map((med) => med.price),
            };
          }
          return item;
        })
      );

      window.location.reload();
      setShow(false);
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
  };

  const handleMedicineChange = (index, event) => {
    const values = [...modalData.medicines];
    values[index][event.target.name] = event.target.value;
    setModalData({
      ...modalData,
      medicines: values,
    });
  };

  const handleAddMedicine = () => {
    setModalData({
      ...modalData,
      medicines: [...modalData.medicines, { name: '', price: '' }],
    });
  };

  const handleRemoveMedicine = (index) => {
    const values = [...modalData.medicines];
    values.splice(index, 1);
    setModalData({
      ...modalData,
      medicines: values,
    });
  };

  const combineHistory = () => {
    return modalData.medicineHistory.map((entry, index) => ({
      date: entry.date,
      medicines: entry.medhub.map((name, idx) => ({
        name,
        price: entry.medhubPrice[idx],
      })),
      dressing: entry.dressing,
      dressingCost: entry.dressingCost,
    }));
  };

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <p className="display-5 p-3">ShriMohanJi Bones Care Data</p>
        <Grid
          data={data.map((row) => [
            row.customId,
            row.date,
            row.name,
            row.Address,
            row.phoneNumber,
            _(
              <div className="d-flex gap-2">
                <Button className="btn bg-danger-outline" onClick={() => handleShow(row, false)}>
                  <FaEye />
                </Button>
                <Button className="btn bg-danger-outline" onClick={() => handleShow(row, true)}>
                  <FaEdit />
                </Button>
                <Button className="btn bg-danger-outline" onClick={() => handleDelete(row.id)}>
                  <MdDeleteForever />
                </Button>
              </div>
            ),
          ])}
          columns={['S.No', 'Date', 'Patient Name', 'Address', 'Mobile Number','Action']}
          search={true}
          pagination={{
            enabled: true,
            limit: 70,
          }}
          className={{
            table: 'table table-striped',
          }}
        />

        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              {isEdit ? 'Edit Details' : (
                <div>
                  <div className="d-flex">
                    <img className="w-75 m-auto" src={hd1} alt="" />
                  </div>
                </div>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalData && (
              <div>
                {isEdit ? (
                  <Form>
                    <Form.Group controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" name="name" value={modalData.name} onChange={handleChange}  />
                    </Form.Group>
                    <Form.Group controlId="formAge">
                      <Form.Label>Age</Form.Label>
                      <Form.Control type="number" name="age" value={modalData.age} onChange={handleChange} disabled />
                    </Form.Group>
                    <Form.Group controlId="formDate">
                      <Form.Label>Update Date</Form.Label>
                      <Form.Control type="date" name="date" value={modalData.date} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="formTotalVisit">
                      <Form.Label>How Many Visits Almost</Form.Label>
                      <Form.Control type="text" name="TotalVisit" value={modalData.TotalVisit} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="formoutStandingAmount">
                      <Form.Label>Balance</Form.Label>
                      <Form.Control type="number" name="outStandingAmount" value={modalData.outStandingAmount} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="formDressing">
                      <Form.Label>Treatment</Form.Label>
                      <Form.Control type="text" name="dressing" value={modalData.dressing} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group controlId="formDressingCost">
                      <Form.Label>Treatment Cost</Form.Label>
                      <Form.Control type="number" name="dressingCost" value={modalData.dressingCost} onChange={handleChange} />
                    </Form.Group>
                    <Form.Label>Medicines</Form.Label>
                    {modalData.medicines.map((medicine, index) => (
                      <div key={index} className="medicine">
                        <Form.Group controlId={`formMedicineName${index}`}>
                          <Form.Label>Medicine Name</Form.Label>
                          <Form.Control type="text" name="name" value={medicine.name} onChange={(event) => handleMedicineChange(index, event)} />
                        </Form.Group>
                        <Form.Group controlId={`formMedicinePrice${index}`}>
                          <Form.Label>Medicine Price</Form.Label>
                          <Form.Control type="number" name="price" value={medicine.price} onChange={(event) => handleMedicineChange(index, event)} />
                        </Form.Group>
                        <Button variant="danger" onClick={() => handleRemoveMedicine(index)}>Remove</Button>
                      </div>
                    ))}
                    <Button variant="primary" onClick={handleAddMedicine}>Add Medicine</Button>
                  </Form>
                ) : (
                  <div>
                    <Table striped bordered hover>
                      <tbody>
                        <tr>
                          <td><strong>Custom ID:</strong></td>
                          <td>{modalData.customId}</td>
                        </tr>
                        <tr>
                          <td><strong>Date:</strong></td>
                          <td>{modalData.date}</td>
                        </tr>
                        <tr>
                          <td><strong>Name:</strong></td>
                          <td>{modalData.name}</td>
                        </tr>
                        <tr>
                          <td><strong>Age:</strong></td>
                          <td>{modalData.age}</td>
                        </tr>
                        <tr>
                          <td><strong>Address:</strong></td>
                          <td>{modalData.Address}</td>
                        </tr>
                        <tr>
                          <td><strong>Mobile Number:</strong></td>
                          <td>{modalData.phoneNumber}</td>
                        </tr>
                      
                        <tr>
                          <td><strong>Disease:</strong></td>
                          <td>{modalData.Disease}</td>
                        </tr>
                        <tr>
                          <td><strong>Visit Almost:</strong></td>
                          <td>{modalData.TotalVisit}</td>
                        </tr>
                        <tr>
                          <td><strong>Other Disease:</strong></td>
                          <td>{modalData.otherDisease}</td>
                        </tr>
                        <tr>
                          <td><strong>Case Detail:</strong></td>
                          <td>{modalData.CaseDetail}</td>
                        </tr>
                        <tr>
                          <td><strong>Treatment:</strong></td>
                          <td>{modalData.dressing}</td>
                        </tr>
                        <tr>
                          <td><strong>Treatment Cost:</strong></td>
                          <td>Rs.{modalData.dressingCost}/-</td>
                        </tr>
                         <tr>
                          <td><strong>Medicine:</strong></td>
                          <td> 
                          {modalData.medhub.map((med, index) => (
                                  <div key={index}>{med}</div>
                                ))}
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Medicine Price:</strong></td>
                          <td>                         {modalData.medhubPrice.map((med, index) => (
                                  <div key={index}>Rs.{med}/-</div>
                                ))}</td>
                        </tr>
                        <tr>
                          <td><strong>Balance:</strong></td>
                          <td>Rs.{modalData.outStandingAmount}/-</td>
                        </tr>
                      </tbody>
                    </Table>
                    <h5>Treatment Details</h5>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Medicine Name</th>
                          <th>Medicine Price</th>
                          <th>Treatment</th>
                          <th>Treatment Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        {combineHistory().map((history, index) => (
                          <tr key={index}>
                            <td>{history.date}</td>
                            <td>
                              <ul  >
                                {history.medicines.map((medicine, idx) => (
                                  <li  key={idx}>{medicine.name}</li>
                                ))}
                              </ul>
                            </td>
                            <td>
                              <ul>
                                {history.medicines.map((medicine, idx) => (
                                  <li key={idx}>Rs. {medicine.price}/-</li>
                                ))}
                              </ul>
                            </td>
                            <td>{history.dressing}</td>
                            <td>Rs. {history.dressingCost}/-</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>
            )}
          </Modal.Body>
          {isEdit && (
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default UserTable;





//   import React, { useState, useEffect } from 'react';
//   import axios from 'axios';
//   import { Modal, Button, Form } from 'react-bootstrap';
//   import { FaEye, FaEdit } from 'react-icons/fa';
//   import { MdDeleteForever } from 'react-icons/md';
//   import { Grid, _ } from 'gridjs-react';
//   import 'gridjs/dist/theme/mermaid.css';
//   import 'bootstrap/dist/css/bootstrap.min.css';
//   import Header from '../layouts/Header';
//   import Footer from '../layouts/Footer';
//   import hd1 from '../assets/img/hd2.png';
  
//   const UserTable = () => {
//     const [data, setData] = useState([]);
//     const [show, setShow] = useState(false);
//     const [modalData, setModalData] = useState(null);
//     const [isEdit, setIsEdit] = useState(false);
//     const handleClose = () => setShow(false);
  
//     useEffect(() => {
//       const fetchData = async () => {
//         try {
//           const response = await axios.get('https://mohanbackend.onrender.com/users');
//           setData(response.data);
//         } catch (error) {
//           console.error('Error fetching data:', error);
//         }
//       };
  
//       fetchData();
//     }, []);
  
//     const toIST = (dateStr) => {
//       const date = new Date(dateStr);
//       return date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }).split(',')[0];
//     };
  
//     const handleShow = (row, isEdit) => {
//       setModalData({
//         ...row,
//         date:  toIST(row.date),
//         medhub: Array.isArray(JSON.parse(row.medhub || '[]')) ? JSON.parse(row.medhub || '[]') : [],
//         medhubPrice: Array.isArray(JSON.parse(row.medhubPrice || '[]')) ? JSON.parse(row.medhubPrice || '[]') : [],
//         medicineHistory: row.medicineHistory ? JSON.parse(row.medicineHistory) : [],
//         previousDates: row.previousDates ? JSON.parse(row.previousDates).map(toIST) : [],
       
//       });
//       setIsEdit(isEdit);
//       setShow(true);
//     };
  
//     const handleDelete = async (id) => {
//       try {
//         await axios.delete(`https://mohanbackend.onrender.com/users/delete/${id}`);
//         setData(data.filter(item => item.id !== id));
//       } catch (error) {
//         console.error('Error deleting record:', error);
//       }
//     };
  
//     const handleSave = async () => {
//       try {
//         const updateData = {
//           date: modalData.date, // Convert back to ISO format
//         TotalVisit: modalData.TotalVisit,
//         medhub: JSON.stringify(modalData.medhub),
//         medhubPrice: JSON.stringify(modalData.medhubPrice),
//         previousDates: JSON.stringify([...modalData.previousDates, modalData.date]),
//         medicineHistory: JSON.stringify([...modalData.medicineHistory, {
//           date: modalData.date,
//           medhub: modalData.medhub,
//           medhubPrice: modalData.medhubPrice,
//         }]),
//         };
  
//         await axios.put(`https://mohanbackend.onrender.com/users/${modalData.id}`, updateData);
  
//         setData(data.map(item => {
//           if (item.id === modalData.id) {
//             return {
//               ...item,
//               ...updateData,
//               date: modalData.date,
//               medhub: modalData.medhub,
//               medhubPrice: modalData.medhubPrice,
//               medicineHistory:modalData.medicineHistory,
//               // medicines: [{ name: '', price: '' }]
//             };
//           }
//           return item;
//         }));
//   window.location.reload()
//         setShow(false);
//       } catch (error) {
//         console.error('Error updating data:', error);
//       }
//     };
  
//     const handleChange = (e) => {
//       const { name, value } = e.target;
//       setModalData({
//         ...modalData,
//         [name]: value,
//       });
//     };
  
  
//     return (
//       <>
//         <Header />
//         <div className="main main-app p-3 p-lg-4">
//           <p className='display-5 p-3'>ShriMohanJi Bones Care Data</p>
//           <Grid
//             data={data.map((row) => [
//               row.customId,
//               row.date,
//               row.name,
//               row.Address,
//               _(
//                 <div className='d-flex gap-2'>
//                   <Button
//                     className="btn bg-danger-outline"
//                     onClick={() => handleShow(row, false)}
//                   >
//                     <FaEye />
//                   </Button>
//                   <Button
//                     className="btn bg-danger-outline"
//                     onClick={() => handleShow(row, true)}
//                   >
//                     <FaEdit />
//                   </Button>
//                   <Button
//                     className="btn bg-danger-outline"
//                     onClick={() => handleDelete(row.id)}
//                   >
//                     <MdDeleteForever />
//                   </Button>
//                 </div>
//               ),
//             ])}
//             columns={['S.No', 'Date', 'Patient Name', 'Address', 'Action']}
//             search={true}
//             pagination={{
//               enabled: true,
//               limit: 70,
//             }}
//             className={{
//               table: 'table table-striped',
//             }}
//           />
  
//           <Modal size='lg' show={show} onHide={handleClose}>
//             <Modal.Header>
//               <Modal.Title>
//               {isEdit ? 'Edit Details' : 
//                 <div>
//                   <div className='d-flex'>
//                     <img className='w-75 m-auto' src={hd1} alt='' />
//                   </div>
//                 </div>}
//               </Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               {modalData && (
//                 <div>
//                   {isEdit ? (
//                     <Form>
//                       <Form.Group controlId="formName">
//                         <Form.Label>Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="name"
//                           value={modalData.name}
//                           onChange={handleChange}
//                           disabled
//                         />
//                       </Form.Group>
//                       <Form.Group controlId="formAge">
//                         <Form.Label>Age</Form.Label>
//                         <Form.Control
//                           type="number"
//                           name="age"
//                           value={modalData.age}
//                           onChange={handleChange}
//                           disabled
//                         />
//                       </Form.Group>
//                       <Form.Group controlId="formDate">
//                         <Form.Label>Update Date</Form.Label>
//                         <Form.Control
//                           type="date"
//                           name="date"
//                           value={modalData.date}
//                           onChange={handleChange}
//                         />
//                       </Form.Group>
//                       <Form.Group controlId="formTotalVisit">
//                         <Form.Label>Total Visit</Form.Label>
//                         <Form.Control
//                           type="number"
//                           name="TotalVisit"
//                           value={modalData.TotalVisit}
//                           onChange={handleChange}
//                         />
//                       </Form.Group>
//                       <Form.Group controlId="formDressing">
//                         <Form.Label>Dressing</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="dressing"
//                           value={modalData.dressing}
//                           onChange={handleChange}
//                         />
//                       </Form.Group>
//                       <Form.Group controlId="formDressingCost">
//                         <Form.Label>Dressing Cost</Form.Label>
//                         <Form.Control
//                           type="number"
//                           name="dressingCost"
//                           value={modalData.dressingCost}
//                           onChange={handleChange}
//                         />
//                       </Form.Group>
//                     </Form>
//                   ) : (
//                     <div className='justify-content-center mx-5 gap-3'>
            
//                       <div className='mx-5'>
//                         <p><strong>S.No:</strong><span className='mx-3'>{modalData.customId}</span></p>
//                         <h4><strong>Patient Name:</strong> <span className='mx-3'>{modalData.name}</span></h4>
//                         <p className='mt-3'><strong>Date:</strong><span className='mx-3'>{modalData.date}</span></p>
//                         <p>
//                           <strong>Previous Meeting Dates:</strong>
//                           <span className='mx-3'>
//                             {Array.isArray(modalData.previousDates) ? modalData.previousDates.join(', ') : ''}
//                           </span>
//                         </p>
//                         <p><strong>Age:</strong><span className='mx-3'>{modalData.age}</span></p>
//                         <p><strong>Sex:</strong><span className='mx-3'>{modalData.sex}</span></p>
//                         <p><strong>X-Ray/MRI:</strong><span className='mx-3'>{modalData.TypeData}</span></p>
//                         <p><strong>Address:</strong><span className='mx-3'>{modalData.Address}</span></p>
//                         <p><strong>Disease:</strong><span className='mx-3'>{modalData.Disease}</span></p>
//                         <p><strong>Other Disease:</strong><span className='mx-3'>{modalData.otherDisease}</span></p>
//                         <p><strong>Case Detail:</strong><span className='mx-3'>{modalData.CaseDetail}</span></p>
//                         <p><strong>Total Visit:</strong><span className='mx-3'>{modalData.TotalVisit}</span></p>
//                         <table className='table table-responsive'>
//                           <thead>
//                             <tr>
//                               <th scope="col">Dressing</th>
//                               <th scope="col">Dressing Cost</th>
//                               <th scope="col">Medicine</th>
//                               <th scope="col">Medicine Price</th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             <tr>
//                               <td>{modalData.dressing}</td>
//                               <td>{modalData.dressingCost}</td>
//                               <td>
//                                 {modalData.medhub.map((med, index) => (
//                                   <div key={index}>{med}</div>
//                                 ))}
//                               </td>
//                               <td>
//                                 {modalData.medhubPrice.map((price, index) => (
//                                   <div key={index}>{price}</div>
//                                 ))}
//                               </td>
//                             </tr>
//                           </tbody>
//                         </table>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               )}
//             </Modal.Body>
//             <Modal.Footer>
//               <Button variant="secondary" onClick={() => setShow(false)}>
//                 Close
//               </Button>
//               {isEdit && (
//                 <Button variant="primary" onClick={handleSave}>
//                   Save Changes
//                 </Button>
//               )}
//             </Modal.Footer>
//           </Modal>
  
//           <Footer />
//         </div>
//       </>
//     );
//   };
  
//   export default UserTable;
  
  
  