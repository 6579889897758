
const dashboardMenu = [
 
  {
    "label": "Add Patient",
    "link": "/dashboard/add-user",
    "icon": "ri-calendar-todo-line"
  },
  {
    "label": "User Data",
    "link": "/dashboard/user-table",
    "icon": "ri-shopping-bag-3-line"
  }
];



export { dashboardMenu };